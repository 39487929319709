import { TextField } from '@mui/material';
import { trim } from '../../../shared/utils';

function InputText({
  onChange, onBlur = () => {}, error, helperText, ...rest
} = {}) {
  const handleTextChange = (e) => {
    const { value: cValue, name: cName } = e.target;
    onChange(cName, trim({ value: cValue, type: 'start' }));
  };
  const handleBlur = (e) => {
    const { value: cValue, name: cName } = e.target;
    onBlur(cName, trim({ value: cValue, type: 'end' }));
    onChange(cName, trim({ value: cValue, type: 'end' }));
  };
  return (
    <TextField
      {...rest}
      fullWidth
      onChange={handleTextChange}
      onBlur={handleBlur}
      error={!!error}
      helperText={error?.message || helperText}
      // This was causing a warning issue due to controlled components not expecting default value to be provided
      // defaultValue=""
      autoComplete="off"
      inputProps={{
        maxLength: 501,
      }}
    />
  );
}

export default InputText;
