import { useState, useEffect } from 'react';
import {
  isEmpty, isEqual, isFunction, some,
} from 'lodash';
import { TextField, Divider } from '@mui/material';
import MUIAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const getMatchingOption = (options, value) => {
  return options.find((option) => option.value === value) || null;
};

function Autocomplete({
  name,
  label,
  value,
  onChange,
  onBlur,
  options,
  disabled,
  error,
  filterOptions = createFilterOptions(),
  optionValidations = { validateOptions: true },
  dataTestId,
  ...rest
}) {
  const { validateOptions, allOptions } = optionValidations;
  const [selected, setSelected] = useState(getMatchingOption(options, value));
  const handleChange = (_, change) => {
    setSelected(change);
    onChange(name, change?.value);
  };

  useEffect(() => {
    if (value !== selected?.value) {
      setSelected(getMatchingOption(allOptions || options, value));
    }
  }, [value]);

  useEffect(() => {
    if (validateOptions && value && !some(options, { value })) {
      setSelected({ value: '' });
      onChange(name, '');
    }
  }, [options]);

  const handleOnClose = (e, reason) => {
    if (reason === 'blur' && isFunction(onBlur)) {
      onBlur();
    }
  };

  const handleInputOnBlur = () => {
    if (isFunction(onBlur)) {
      onBlur();
    }
  };
  return (
    <MUIAutocomplete
      isOptionEqualToValue={(opt, val) => {
        if (typeof val === 'object') return isEqual(opt, val);
        if (!isEmpty(val)) return opt === val;
        return true;
      }}
      value={selected}
      disabled={disabled}
      name={name}
      autoComplete
      autoSelect
      onChange={handleChange}
      options={options}
      onClose={handleOnClose}
      filterOptions={filterOptions}
      data-testid={dataTestId}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...rest}
            onBlur={handleInputOnBlur}
            // inputProps={inputProps}
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            label={label}
          />
        );
      }}
      renderOption={(props, option) => (
        <div key={option.text}>
          <li {...props} data-testid={`${dataTestId}${option.label || option.text}`}>{option.label || option.text}</li>
          {option.showDivider && <Divider variant="middle" />}
        </div>
      )}
      getOptionLabel={(o) => o?.label || o?.text || ''}
    />
  );
}

export default Autocomplete;
