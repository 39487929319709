import {
  Box,
  Typography,
} from '@mui/material';
import PersistedSectionForm from '../../shared/PersistedSectionForm';
import { ControlledInputText } from '../../forms/InputText';
import { AccordionPersist } from '../../shared/Accordion/Accordion';

const Comments = ({ control }) => {
  return (
    <>
      <Box
        display="grid"
      >
        <Typography color="textPrimary" variant="body1" sx={{ mb: 4 }}>
          Please provide any additional information about your organization relevant to the Master Agreement.
        </Typography>
        <ControlledInputText
          id="outlined-multiline-flexible"
          multiline
          rows={5}
          name="comments"
          control={control}
          data-testId="comments"
        />
      </Box>
    </>
  );
};

const label = 'Comments';
const section = 'addAccount.comments';

export default AccordionPersist({
  section,
  label,
  Component: PersistedSectionForm({
    section,
    Component: Comments,
    defaultValues: {
      comments: '',
    },
  }),
});
