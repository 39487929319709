import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { get, isEmpty } from 'lodash';
import {
  ManageEntities,
  useEntityManager,
} from '.';
import { setProp } from '../../../redux/slices/persistedForms';

const PersistedManageEntities = ({
  section,
  Component,
  schema,
  defaultValues,
  entityManagerProps,
  validate,
  withContainer = true,
  dataTestId = '',
}) => (props) => {
  const mode = useSelector((state) => {
    return get(state, `persistedFormsReducer.${section}.mode`);
  });
  const savedEntities = useSelector((state) => {
    return get(state, `persistedFormsReducer.${section}.savedEntities`, []);
  });
  const initialForm = useSelector((state) => {
    return get(state, `persistedFormsReducer.${section}.form`, {});
  });
  const shouldReset = useSelector((state) => {
    return get(state, `persistedFormsReducer.${section}.shouldReset`);
  });
  const dispatcher = useDispatch();
  const {
    control,
    reset,
    formState: { isValid },
    getValues,
    watch,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(Yup.object().shape(schema)),
    defaultValues: { ...defaultValues, ...initialForm },
  });

  useEffect(() => {
    if (shouldReset) {
      reset(defaultValues);
      dispatcher(setProp({ sectionPath: `${section}.shouldReset`, value: false }));
      dispatcher(setProp({ sectionPath: `${section}.form`, value: defaultValues }));
    }
  }, [shouldReset]);

  const contentReset = (data = {}) => {
    const newData = !isEmpty(data) ? data : defaultValues;
    reset(newData);
    dispatcher(setProp({ sectionPath: `${section}.form`, value: newData }));
  };

  watch((changes, { type, name }) => {
    if (type === 'change') {
      dispatcher(setProp({ sectionPath: `${section}.form.${name}`, value: changes[name] }));
    }
  });

  const onUpdate = (updatedEntities) => {
    dispatcher(setProp({ sectionPath: `${section}.savedEntities`, value: updatedEntities }));
    contentReset();
  };

  const entityManager = useEntityManager({
    getValues,
    onUpdate,
    onDelete: onUpdate,
    entities: savedEntities,
    onReset: contentReset,
  });

  const handleSetMode = (newMode) => {
    dispatcher(setProp({ sectionPath: `${section}.mode`, value: newMode }));
  };

  return (
    <ManageEntities
      {...entityManagerProps}
      disableSave={!isValid}
      contentReset={contentReset}
      sidebarList={savedEntities}
      initialMode={mode}
      onSaveNew={entityManager.onSaveNew}
      onDelete={entityManager.onDelete}
      onSelected={entityManager.onSelected}
      onSaveEdit={entityManager.onSaveEdit}
      onModeChange={handleSetMode}
      dataTestId={dataTestId}

    >
      {withContainer ? (
        <Grid container spacing={1} pt={2}>
          <Component control={control} validate={validate} dataTestId={dataTestId} {...props} />
        </Grid>
      ) : <Component control={control} validate={validate} dataTestId={dataTestId} {...props} />}
    </ManageEntities>
  );
};

export default PersistedManageEntities;
