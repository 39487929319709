import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import usePrevious from '../../../hooks/usePrevious';
import useFormPersist from './useFormPersist';

const PersistedSectionForm = ({
  Component, section, schema, defaultValues, dataTestId = '',
}) => (props = {}) => {
  const previousActive = usePrevious(props.isActive);
  const {
    control, setValue, setError, clearErrors, watch, reset, trigger, getValues, formState: { isDirty, errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(Yup.object().shape(schema)),
    defaultValues,
    context: {},
  });

  const copyValues = (valuesToCopy) => {
    reset(valuesToCopy);
    setTimeout(() => trigger(), 500);
  };

  const { shouldReset: onReset } = useFormPersist(section, {
    watch,
    setValue,
    resetValues: defaultValues,
    reset,
    isDirty,
    trigger,
    errors,
    validate: props.validate,
  });

  useEffect(() => {
    async function validateIfChangedToInactive() {
      const isChangeOfActive = previousActive && !props.isActive;
      if (isChangeOfActive && !onReset) {
        await trigger();
      }
    }
    validateIfChangedToInactive();
  }, [previousActive, props.isActive, onReset]);

  return <Component control={control} watch={watch} setValue={setValue} clearErrors={clearErrors} setError={setError} copyValues={copyValues} getValues={getValues} dataTestId={dataTestId} {...props} />;
};

export default PersistedSectionForm;
